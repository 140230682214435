@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
html,
body,
#root {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  color: #275e77;
}

a:visited,
a:link,
a:active {
  color: inherit;
}

.Home_ImageWrapper__7oat7 {
  height: calc(100% - 8rem);
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.Home_Image__AElqy {
  display: block;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: scale-down;
}

.Header_Header__1fHt3,
.Header_Footer__2J3wK {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.5rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .Header_Header__1fHt3,
  .Header_Footer__2J3wK {
    padding-right: 0;
    justify-content: center !important;
  }
}

.Info_Page__oPR9z {
  padding: 2rem;
  padding-top: 1rem;
}

.Info_Page__oPR9z section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Info_Page__oPR9z li {
  margin-bottom: 2px;
}

.Info_Page__oPR9z section {
  margin-bottom: 1.8rem;
}

.Info_Page__oPR9z section span {
  display: block;
}

.Info_Page__oPR9z strong {
  display: inline-block;
  margin-bottom: 0.5rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .Info_Page__oPR9z {
    padding-top: 0;
  }
}

.App_App__16ZpL {
  height: 100%;
}

