@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  color: #275e77;
}

a:visited,
a:link,
a:active {
  color: inherit;
}
