.ImageWrapper {
  height: calc(100% - 8rem);
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.Image {
  display: block;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: scale-down;
}
