.Header,
.Footer {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.5rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .Header,
  .Footer {
    padding-right: 0;
    justify-content: center !important;
  }
}
