.Page {
  padding: 2rem;
  padding-top: 1rem;
}

.Page section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Page li {
  margin-bottom: 2px;
}

.Page section {
  margin-bottom: 1.8rem;
}

.Page section span {
  display: block;
}

.Page strong {
  display: inline-block;
  margin-bottom: 0.5rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  .Page {
    padding-top: 0;
  }
}
